



































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import FcThumbnail from '@/components/FcThumbnail.vue';
import FcImage from '@/components/FcImage.vue';
import { useVideos, getVideosSortType, getVideosOrderType, Video } from '@/composition/video';
import { useNotification } from '@/composition/notification';
import { confirmDialog } from '@/composition/confirm';

export default defineComponent({
  name: 'Videos',
  components: {
    FcRoleLoading,
    FcRoleDeny,
    FcThumbnail,
    FcImage,
  },
  setup() {
    // データ取得・データ定義
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('videos'));
    const { notify, error } = useNotification();
    const { videos } = useVideos();
    const isLoading = ref(true);
    const isOpenUpdateThumbnailDialog = ref(false); // サムネイル更新時ダイアログ表示フラグ
    const isUpdateThumbnail = ref(false); // サムネイルを更新しようとしているかのフラグ(コンポーネント表示出しわけで利用)
    const isUpdatingThumbnail = ref(false); // サムネイル更新時の読み込みフラグ
    const updateThumbnailVideo = ref<Video | undefined>(); // サムネイル更新対象の動画
    const updateThumbnailPath = ref(''); // サムネイルの画像パス

    // 画面表示用データ定義
    const headers = [
      { text: 'サムネイル', value: 'thumbnail' },
      { text: '動画名', value: 'videoName' },
      { text: '作成日時', value: 'createDate.dateTimeSeconds' },
      { text: '更新日時', value: 'updateDate.dateTimeSeconds' },
      { text: '終了画面タイプ', value: 'displayEndScreenType' },
      { text: '再生回数', value: 'views' },
      { text: 'ID', value: 'videoId' },
      { text: '', value: 'actions', width: '200px', sortable: false, align: 'end' },
    ];
    const sortTypes = Object.values(getVideosSortType);
    const orderTypes = Object.values(getVideosOrderType).reverse();
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    // 使用する関数定義
    function openUpdateThumbnailDialog(videoId: string) {
      isUpdateThumbnail.value = false;
      isOpenUpdateThumbnailDialog.value = true;
      try {
        const targetVideo = videos.getVideo(videoId);
        updateThumbnailVideo.value = targetVideo;
      } catch (e) {
        error(e);
      }
    }
    function cancelUpdateThumbnailDialog() {
      updateThumbnailVideo.value = undefined;
      updateThumbnailPath.value = '';
      isUpdateThumbnail.value = false;
      isOpenUpdateThumbnailDialog.value = false;
    }

    async function getMessages(page?: number) {
      isLoading.value = true;
      try {
        await videos.getVideos(page);
      } catch (e) {
        error(e);
      } finally {
        isLoading.value = false;
      }
    }

    async function copyToClipBoard(text: string, type: 'embedHtml' | 'id') {
      await navigator.clipboard.writeText(text);
      let notifyString = '';
      if (type === 'embedHtml') {
        notifyString = '埋め込みコードをコピーしました';
      } else if (type === 'id') {
        notifyString = '動画IDをコピーしました';
      }
      notify(notifyString);
    }

    // 初期実行関数
    async function init() {
      await getMessages();
    }
    init();

    async function deleteVideo(videoId: string) {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      try {
        const targetVideo = videos.getVideo(videoId);
        await targetVideo.deleteVideo();
        await init();
        notify('指定された動画を削除しました');
      } catch (e) {
        error(e);
      }
    }

    async function updateVideoThumbnail(video: Video) {
      try {
        const thumbnailPath = updateThumbnailPath.value;
        if (!thumbnailPath) {
          error('サムネイルをアップロードしてください');
          return;
        }
        isUpdatingThumbnail.value = true;
        await video.updateVideoThumbnail(thumbnailPath);
        isUpdatingThumbnail.value = false;
        updateThumbnailVideo.value = undefined;
        updateThumbnailPath.value = '';
        isOpenUpdateThumbnailDialog.value = false;
        await init();

        notify('サムネイルを更新しました');
      } catch (e) {
        error(e);
      }
    }

    // ソート条件が変更時はデータのリフレッシュ
    watch(
      () => [videos.displaySortType, videos.displayOrderType],
      () => {
        getMessages();
      }
    );
    return {
      pageTitle: '動画一覧',
      myRoleSettings,
      headers,
      sortTypes,
      orderTypes,
      tableHeight,
      isLoading,
      isOpenUpdateThumbnailDialog,
      isUpdateThumbnail,
      isUpdatingThumbnail,
      updateThumbnailVideo,
      updateThumbnailPath,
      getMessages,
      copyToClipBoard,
      deleteVideo,
      updateVideoThumbnail,
      openUpdateThumbnailDialog,
      cancelUpdateThumbnailDialog,
      videos,
    };
  },
});
