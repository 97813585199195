import core from '@/admin/core';
import { GetVideosOrderType, GetVideosSortType, VideoEndScreenType } from '@/composition/video';

// 一件取得レスポンス
export interface VideoResponse {
  videoId: string;
  videoName: string;
  description: string;
  url: string;
  embedHtml: string;
  embedDomains?: string[];
  endScreenType: keyof VideoEndScreenType;
  thumbnail: string;
  durationSeconds: number;
  views: number;
  parentFolderId: string;
  createDate: number;
  updateDate: number;
}

export interface CreateVideoRequest {
  videoName: string;
  videoPath: string;
  description: string;
  embedDomains: string[];
}
export interface UpdateVideoRequest {
  videoName: string;
  description: string;
  embedDomains: string[];
  endScreenType: keyof VideoEndScreenType;
}

// 一覧取得時レスポンス
interface VideosResponse {
  videos: VideoResponse[];
  currentPage: number;
  totalPage: number;
}
export interface GetVideosQueryProps {
  page: number;
  sortType: keyof GetVideosSortType;
  orderType: keyof GetVideosOrderType;
}

export const createVideo = async (props: CreateVideoRequest) => {
  const result = await core.httpClient.post(`/admin/public/videos`, props);
  return result.data.videoId as string;
};
export const updateVideo = async (videoId: string, props: UpdateVideoRequest) => {
  await core.httpClient.put(`/admin/public/videos/${videoId}`, props);
};
export const updateVideoThumbnail = async (videoId: string, imagePath: string) => {
  await core.httpClient.put(`/admin/public/videos/${videoId}/thumbnail`, { imagePath });
};
export const deleteVideo = async (videoId: string) => {
  await core.httpClient.delete(`/admin/public/videos/${videoId}`);
};
export const getVideo = async (videoId: string) => {
  const result = await core.httpClient.get(`/admin/public/videos/${videoId}`);
  return result.data ? (result.data as VideoResponse) : undefined;
};

export const getVideos = async (props: GetVideosQueryProps) => {
  const result = await core.httpClient.get(
    `/admin/public/videos?sortType=${props.sortType}&orderType=${props.orderType}${
      props.page ? `&page=${props.page}` : ''
    }`
  );
  return result.data as VideosResponse;
};
