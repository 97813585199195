import {
  VideoResponse,
  GetVideosQueryProps,
  getVideo,
  getVideos,
  deleteVideo,
  updateVideoThumbnail,
  CreateVideoRequest,
  UpdateVideoRequest,
  createVideo,
  updateVideo,
} from '@/admin/video';
import { DisplayDate, getKeyByValue, valueOf } from '@/admin/util';
import { reactive } from '@vue/composition-api';

export const videoEndScreenType = {
  empty: '空',
  loop: 'ループ',
  thumbnail: 'サムネイル表示',
} as const;
export type VideoEndScreenType = typeof videoEndScreenType;
export const getVideosSortType = {
  createDate: '作成日時順',
  updateDate: '更新日時順',
  videoName: '動画名順',
} as const;
export type GetVideosSortType = typeof getVideosSortType;
export const getVideosOrderType = {
  2: '降順',
  1: '昇順',
} as const;
export type GetVideosOrderType = typeof getVideosOrderType;
const adminDomainName = (process.env.VUE_APP_API_ENDPOINT as string).replace('https://api.', '');
const domainName = (process.env.VUE_APP_HOSTING_URL as string).replace('https://', '');
const unit04DomainName = 'unit04.io';
const defaultVideoEmbedDomains = [unit04DomainName, adminDomainName, domainName] as const;

function convertVideoDurationToHMS(durationSeconds: number) {
  const hours = Math.floor(durationSeconds / (60 * 60));
  const minutes = Math.floor((durationSeconds % (60 * 60)) / 60);
  const seconds = durationSeconds % 60;
  return `${hours ? `${hours}時間` : ''}${minutes ? `${minutes}分` : ''}${seconds ? `${seconds}秒` : ''}`;
}
export class Video {
  videoId = '';
  videoName = '';
  description = '';
  url = '';
  embedHtml = '';
  embedDomains = defaultVideoEmbedDomains.concat();
  displayEndScreenType: valueOf<VideoEndScreenType> = videoEndScreenType.empty;
  thumbnail = '';
  videoDuration = '';
  views = 0;
  parentFolderId = '';
  createDate = new DisplayDate();
  updateDate = new DisplayDate();

  constructor(videoId?: string) {
    if (videoId) this.videoId = videoId;
  }

  setVideoResponse(props: VideoResponse) {
    this.videoId = props.videoId;
    this.videoName = props.videoName;
    this.description = props.description;
    this.url = props.url;
    this.embedHtml = props.embedHtml;
    // 1件取得時のみ取得できるので一覧取得時は取り急ぎ空配列を格納
    this.embedDomains = props.embedDomains ? props.embedDomains : [];
    this.displayEndScreenType = videoEndScreenType[props.endScreenType];
    this.thumbnail = props.thumbnail;
    this.videoDuration = convertVideoDurationToHMS(props.durationSeconds);
    this.views = props.views;
    this.parentFolderId = props.parentFolderId;
    this.createDate = new DisplayDate(props.createDate);
    this.updateDate = new DisplayDate(props.updateDate);
  }

  getExtraEmbedDomains() {
    return this.embedDomains.filter((domain) => !defaultVideoEmbedDomains.includes(domain));
  }
  setExtraEmbedDomains(domain: string) {
    if (domain.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)) {
      throw 'https://を含まない形で入力してください';
    } else if (domain.match(/\/$/)) {
      throw '末尾に / を含めないでください';
    } else if (defaultVideoEmbedDomains.includes(domain)) {
      throw '本サービスで利用するドメインは既に追加されています';
    }
    this.embedDomains.push(domain);
  }
  deleteExtraEmbedDomains(domain: string) {
    if (defaultVideoEmbedDomains.includes(domain)) {
      throw '本サービスで利用するドメインは削除できません';
    }
    const extraEmbedDomains = this.getExtraEmbedDomains();
    if (!extraEmbedDomains.includes(domain)) return;
    this.embedDomains.splice(this.embedDomains.indexOf(domain), 1);
  }

  getEmbedHtml() {
    return `<iframe src="https://player.vimeo.com/video/${this.videoId}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
  }

  async getVideo() {
    if (!this.videoId) return;
    const videoResponse = await getVideo(this.videoId);
    if (!videoResponse) throw '動画が存在しません';
    this.setVideoResponse(videoResponse);
  }

  private getCreateVideoRequest(videoPath: string): CreateVideoRequest {
    return {
      videoName: this.videoName,
      videoPath: videoPath,
      description: this.description,
      embedDomains: this.embedDomains,
    };
  }

  private getUpdateVideoRequest(): UpdateVideoRequest {
    return {
      videoName: this.videoName,
      description: this.description,
      embedDomains: this.embedDomains,
      endScreenType: getKeyByValue<VideoEndScreenType>(videoEndScreenType, this.displayEndScreenType),
    };
  }

  // videoPathは作成時にのみ使用する値のためメンバとして持たない
  async createVideo(videoPath: string) {
    if (!videoPath) throw '動画をアップロードしてください';
    const videoId = await createVideo(this.getCreateVideoRequest(videoPath));
    this.videoId = videoId;
    return videoId;
  }

  async updateVideo() {
    if (!this.videoId) throw 'IDを指定してください';
    await updateVideo(this.videoId, this.getUpdateVideoRequest());
  }

  // thumbnailPathはサムネイル追加時にのみ使用する値のためメンバとして持たない
  async updateVideoThumbnail(thumbnailPath: string) {
    if (!this.videoId) throw 'IDを指定してください';
    if (!thumbnailPath) throw 'サムネイルをアップロードしてください';
    await updateVideoThumbnail(this.videoId, thumbnailPath);
  }

  async deleteVideo() {
    if (!this.videoId) throw 'IDを指定してください';
    await deleteVideo(this.videoId);
  }
}

class Videos {
  videos: Video[] = [];
  displaySortType: valueOf<GetVideosSortType> = '作成日時順';
  displayOrderType: valueOf<GetVideosOrderType> = '降順';
  currentPage = 1;
  totalPage = 1;

  private getVideoQueryProps(page?: number): GetVideosQueryProps {
    return {
      page: page ? page : this.currentPage,
      sortType: getKeyByValue<GetVideosSortType>(getVideosSortType, this.displaySortType),
      orderType: getKeyByValue<GetVideosOrderType>(getVideosOrderType, this.displayOrderType),
    };
  }

  getVideo(videoId: string) {
    const video = this.videos.find((_video) => _video.videoId === videoId);
    if (!video) throw '不明な動画です';
    return video;
  }

  async getVideos(page?: number) {
    if (page) {
      if (this.totalPage < page || page < 0) throw '指定されたページは存在しません';
    }
    const videosResponse = await getVideos(this.getVideoQueryProps(page));
    this.videos = videosResponse.videos.map((videoResponse) => {
      const video = new Video(videoResponse.videoId);
      video.setVideoResponse(videoResponse);
      return video;
    });
    this.currentPage = videosResponse.currentPage;
    this.totalPage = videosResponse.totalPage;
  }
}

export const useVideo = (videoId?: string) => {
  const video = new Video(videoId);
  return { video: reactive(video) };
};
export const useVideos = () => {
  const videos = new Videos();
  return { videos: reactive(videos) };
};
