







import { defineComponent, ref, watch } from '@vue/composition-api';
import { download } from '@/admin/file';

export default defineComponent({
  name: 'FcThumbnail',
  props: {
    image: {
      type: String,
      required: true,
    },
    imgMaxSize: {
      type: String,
      default: '200px',
      required: false,
    },
  },
  setup(props) {
    const src = ref('');
    const downloading = ref(true);
    const imageUrl = props.image;
    const imageStyle = `max-width:${props.imgMaxSize}`;

    async function init(imageUrl: string) {
      // 絶対指定のURL(基本外部リンク)の場合は値をそのまま格納し、相対パスの場合はS３から画像パスを取得する
      if (imageUrl && imageUrl.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)) {
        src.value = imageUrl;
      } else if (imageUrl) {
        const signedUrl = await download(imageUrl);
        src.value = signedUrl;
      }
      downloading.value = false;
    }
    init(imageUrl);
    watch(
      () => props.image,
      () => {
        downloading.value = true;
        init(props.image);
      }
    );

    return { src, imageStyle, downloading };
  },
});
